import { useEffect, useState } from 'react';
import { performAuthenticatedGetRequest } from '../../../../../utils/api/handlers';
import appNotify from '../../../../../utils/libs/appNotify';

export default function useCities(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [cityOptions, setCityOptions] = useState([]);
  const [cityOptionsLoading, setCityOptionsLoading] = useState(false);

  const listCities = () => {
    setPageLoading(true);
    performAuthenticatedGetRequest(`api/v1/cities/list`, params)
      .then((res) => {
        setCities(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to load cities');
        setPageLoading(false);
      });
  };

  const searchCities = () => {
    setCityOptionsLoading(true);
    performAuthenticatedGetRequest(`api/v1/cities/search`, params)
      .then((res) => {
        setCityOptions(res.results);
        setCityOptionsLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to search cities');
        setCityOptionsLoading(false);
      });
  };

  useEffect(() => {
    if (init) {
      listCities();
    } else if (params.search && params.search.length > 0) {
      searchCities();
    }
  }, [params]);

  return {
    pageLoading,
    setCities,
    next,
    cities,
    count,
    listCities,
    cityOptionsLoading,
    setParams,
    cityOptions
  };
}
