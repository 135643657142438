export const PERMISSIONS = {
  DEFAULT: '0',
  // USERS CRUD
  USERS_READ: '1',
  USERS_CREATE: '2',
  USER_UPDATE: '3',
  USER_DELETE: '4',
  USERS_CRUD: ['1', '2', '3', '4'],

  // ROLES CURD
  ROLE_CREATE: '5',
  ROLE_UPDATE: '6',
  ROLE_DELETE: '7',
  ROLE_READ: '8',
  ROLES_CRUD: ['5', '6', '7', '8'],

  // GOODS CRUD
  GOODS_CREATE: '9',
  GOODS_UPDATE: '10',
  GOODS_DELETE: '11',
  GOODS_READ: '13',
  GOODS_CRUD: ['13', '14', '15', '16'],

  // CONSIGNMENTS CRUD
  CONSIGNMENTS_CREATE: '17',
  CONSIGNMENTS_UPDATE: '18',
  CONSIGNMENTS_DELETE: '19',
  CONSIGNMENTS_READ: '20',
  CONSIGNMENTS_CRUD: ['17', '18', '19', '20'],

  // CONTAINERS CRUD
  CONTAINERS_CREATE: '21',
  CONTAINERS_UPDATE: '22',
  CONTAINERS_DELETE: '23',
  CONTAINERS_READ: '24',
  LOAD_CONSIGNMENTS: '45',
  CONTAINERS_CRUD: ['21', '22', '23', '24', '45'],

  // SUPPLIERS CRUD
  SUPPLIERS_CREATE: '25',
  SUPPLIERS_UPDATE: '26',
  SUPPLIERS_DELETE: '27',
  SUPPLIERS_READ: '28',
  SUPPLIERS_CRUD: ['25', '26', '27', '28'],

  // INVOICES CRUD
  INVOICES_CREATE: '29',
  INVOICES_UPDATE: '30',
  INVOICES_DELETE: '31',
  INVOICES_READ: '32',
  INVOICES_CRUD: ['29', '30', '31', '32'],

  // PRICE CATEGORIES CRUD
  PRICE_CATEGORIES_CREATE: '33',
  PRICE_CATEGORIES_UPDATE: '34',
  PRICE_CATEGORIES_DELETE: '35',
  PRICE_CATEGORIES_READ: '36',
  PRICE_CATEGORIES_CRUD: ['33', '34', '35', '36'],

  // SMS CRUD
  SMS_CREATE: '37',
  SMS_UPDATE: '38',
  SMS_DELETE: '39',
  SMS_READ: '40',
  SMS_CRUD: ['37', '38', '39', '40'],

  // CUSTOMERS CRUD
  CUSTOMERS_CREATE: '41',
  CUSTOMERS_UPDATE: '42',
  CUSTOMERS_DELETE: '43',
  CUSTOMERS_READ: '44',
  CUSTOMERS_CRUD: ['41', '42', '43', '44'],

  // BLS_CRUD
  BLS_CREATE: '45',
  BLS_UPDATE: '46',
  BLS_DELETE: '47',
  BLS_READ: '48',
  BLS_CRUD: ['45', '46', '47', '48'],

  // DISPATCH_CRUD
  DISPATCH_AUTHORIZED: '49',
  DISPATCH_EDIT: '50',
  DISPATCH_DELETE: '51',
  DISPATCH_READ: '52',
  DISPATCH_CRUD: ['49', '50', '51', '52'],

  // INVOICE PAYMENTS
  PAYMENT_CREATE: '53',
  PAYMENT_EDIT: '54',
  PAYMENT_DELETE: '55',
  PAYMENT_READ: '56',
  PAYMENT_CRUD: ['53', '54', '55', '56'],

  DISCOUNT_ISSUE: '57',
  DISCOUNT_EDIT: '58',
  DISCOUNT_CRUD: ['57', '58'],

  // OTHERS
  CHANGE_RATE: '101',
  SEE_RATE: '102',

  // MARKETS
  MARKET_CREATE: 'MA_103',
  MARKET_UPDATE: 'MA_104',
  MARKET_DELETE: 'MA_105',
  MARKET_READ: 'MA_106',
  MARKET_CRUD: ['MA_103', 'MA_104', 'MA_105', 'MA_106']
};

// 45 CURRENT
