import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Stack,
  Card,
  Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from '../../../ui-component/cards/MainCard';
import { currencyFormatter, renderDateTime, renderInvoicesStatus } from '../../../utils/libs';
import Config from '../../../utils/Config';

const invoiceDetails = {
  id: 17,
  bill_no: 'BN+653F44E3',
  customer: {
    id: 3,
    full_name: 'Kelvin Remmy',
    email: 'kelvinremmy01@gmail.com',
    created_at: '2024-01-05T13:49:38.351116Z',
    cellphone: '255624351398',
    status: 1
  },
  discount_ptg: '10.00',
  discount_details: 'Discount Description',
  created_by: null,
  status: 1,
  created_at: '2024-01-05T13:53:28.502892Z',
  paid_amount: '0.00',
  amount: '3000.00',
  items: [
    {
      amount: '3000.00',
      id: 20,
      description: 'NON Jeans | 1.00CBM'
    },
    {
      amount: '3000.00',
      id: 20,
      description: 'NON Jeans | 1.00CBM'
    },
    {
      amount: '3000.00',
      id: 20,
      description: 'NON Jeans | 1.00CBM'
    },
    {
      amount: '3000.00',
      id: 20,
      description: 'NON Jeans | 1.00CBM'
    }
  ]
};

export default function Invoice({ width = '100vw', bill = invoiceDetails }) {
  const theme = useTheme();
  const styles = {
    paper: {
      padding: theme.spacing(3),
      paddingTop: 0,
      margin: 0
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1)
    },
    table: {
      width: '100%'
    },
    button: {
      margin: theme.spacing(1)
    },
    card: {
      backgroundColor: '#e3f2fd',
      border: '1px solid rgb(238, 238, 238)',
      padding: theme.spacing(2)
    }
  };
  const discount = (parseFloat(bill.discount_ptg) / 100) * parseFloat(bill.amount);
  const netAmount = parseFloat(bill.amount) - discount - parseFloat(bill.paid_amount);
  return (
    <MainCard
      title={
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <img src="https://cmts.gnmcargo.com/u/image/gnm.png" alt="GNMCARGO" width="50" height={50} />
          </Grid>
          <Grid item>
            <Typography variant="h3" align="right">
              Customer Cargo Invoice{' '}
              <span>
                <Typography style={{ fontSize: 20 }} variant="caption">
                  |
                </Typography>{' '}
              </span>{' '}
              #{bill.id}
            </Typography>
          </Grid>
        </Grid>
      }
      className="divToPrint"
      style={{
        // maxWidth: 842,
        // minWidth: 842
        minHeight: '100vh',
        width
      }}
    >
      <Paper style={styles.paper}>
        <Stack spacing={2}>
          <Grid container spacing={1} justifyContent="left">
            <Grid item xs={6}>
              <Typography style={styles.title} variant="subtitle1" align="left">
                From
              </Typography>
              <Typography variant="h6" align="left">
                GNMCARGO Company LTD.
              </Typography>
              <Typography variant="h6" align="left">
                Msimbazi, Kariakoo, Dar es Salaam
              </Typography>
              <Typography variant="h6" align="left">
                info@gnmcargoltd.com
              </Typography>
              <Typography variant="h6" align="left">
                (+255) 755 999 999
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={styles.title} variant="subtitle1" align="left">
                Invoice Details
              </Typography>
              <Grid container justifyContent="center">
                <Grid item xs={7}>
                  <Typography variant="h6" align="left">
                    Date of Issue:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" align="right">
                    {renderDateTime(bill.created_at)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={7}>
                  <Typography variant="h6" align="left">
                    Payment due on:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" align="right">
                    {renderDateTime(bill.created_at)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={7}>
                  <Typography variant="h6" align="left">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" align="right">
                    {renderInvoicesStatus(bill.status)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="left">
            <Grid item xs={6}>
              <Typography style={styles.title} variant="subtitle1" align="left">
                To
              </Typography>
              <Typography variant="subtitle1" align="left">
                {bill.customer.full_name}
              </Typography>
              <Typography variant="h6" align="left">
                {bill.customer.address}
              </Typography>
              <Typography variant="h6" align="left">
                {bill.customer.cellphone}
              </Typography>
              <Typography variant="h6" align="left">
                {bill.customer.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <TableContainer>
                <Table style={styles.table} aria-label="invoice table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bill.items.map((item) => (
                      <TableRow key={item.description}>
                        <TableCell component="th" scope="row">
                          {item.description}
                        </TableCell>
                        <TableCell align="right">{currencyFormatter(item.amount, 'USD')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <Card style={styles.card}>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" align="right">
                      Sub Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" align="right">
                      {currencyFormatter(bill.amount, 'USD')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" align="right">
                      Discount ({bill.discount_ptg}%):
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" align="right">
                      {currencyFormatter(discount, 'USD')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" align="right">
                      Paid Amount:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" align="right">
                      {currencyFormatter(parseFloat(bill.paid_amount), 'USD')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" align="right">
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h3" align="right">
                      {currencyFormatter(netAmount, 'USD')}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </MainCard>
  );
}
