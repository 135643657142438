import { globalNavigator } from '../../App';
import {
  CONSIGNMENT_STATUS,
  CONTAINER_STATUS,
  CUSTOMER_STATUSES,
  DISPATCH_STATUSES,
  GOODS_STATUS,
  INVOICE_PAYMENTS_STATUS,
  INVOICES_STATUS,
  PAYMENT_TYPES,
  SMS_STATUS
} from './constants';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';

export const logoutAction = () => {
  if (globalNavigator !== null) {
    globalNavigator('/login', { replace: true });
  }
  localStorage.clear();
  clearTimeout();
};
export function debuggerConsole(...args) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args);
  }
}

export const renderDateTime = (datetimestring) => dayjs(datetimestring).format('DD-MMM-YYYY HH:mm:ss');

export const currencyFormatter = (_amount, currency) =>
  parseFloat(_amount).toLocaleString('en-US', { style: 'currency', currency, minimumFractionDigits: 1 });

export const renderConsignmentStatus = (status) => {
  if (status === CONSIGNMENT_STATUS.PENDING) return <Chip size="small" label="Pending" color="default" />;
  if (status === CONSIGNMENT_STATUS.ARRIVED) return <Chip size="small" label="Arrived" color="primary" />;
  if (status === CONSIGNMENT_STATUS.SHIPPED) return <Chip size="small" label="Shipped" color="secondary" />;
  if (status === CONSIGNMENT_STATUS.CANCELED) return <Chip size="small" label="Cancelled" color="error" />;
  if (status === CONSIGNMENT_STATUS.DELIVERED) return <Chip size="small" label="Delivered" color="success" />;
  if (status === CONSIGNMENT_STATUS.LOADED) return <Chip size="small" label="Loaded" color="default" />;
  if (status === CONSIGNMENT_STATUS.AUTHORIZED_DISPATCH) return <Chip size="small" label="Authorized Dispatch" color="default" />;
  if (status === CONSIGNMENT_STATUS.DISPATCHED) return <Chip size="small" label="Dispatched" color="success" />;
  return <Chip size="small" label="Pending" color="default" />;
};

export const renderGoodStatus = (status) => {
  if (status === GOODS_STATUS.PENDING) return <Chip size="small" label="Pending" color="default" />;
  if (status === GOODS_STATUS.STOCK) return <Chip size="small" label="Stock" color="secondary" />;
  if (status === GOODS_STATUS.CONSIGNED) return <Chip size="small" label="Consigned" color="primary" />;
  return <Chip size="small" label="Pending" color="default" />;
};

export const renderInvoicesStatus = (status) => {
  if (status === INVOICES_STATUS.COMPLETED_PAYMENTS) return <Chip variant="outlined" size="small" label="Complete" color="success" />;
  if (status === INVOICES_STATUS.ONPROGRESS) return <Chip variant="outlined" size="small" label="On Progress" color="secondary" />;
  if (status === INVOICES_STATUS.CANCELLED) return <Chip variant="outlined" size="small" label="Cancelled" color="error" />;
  return <Chip variant="outlined" size="small" label="Pending" color="default" />;
};

export const renderContainerStatus = (status) => {
  console.log('IF: ', status === CONTAINER_STATUS.SHIPPED, status, CONTAINER_STATUS.SHIPPED);
  if (status === CONTAINER_STATUS.ARRIVED) return <Chip size="small" label="Arrived" color="success" />;
  if (status === CONTAINER_STATUS.LOADED) return <Chip size="small" label="Loaded" color="secondary" />;
  if (status === CONTAINER_STATUS.CANCELED) return <Chip size="small" label="Canceled" color="error" />;
  if (status === CONTAINER_STATUS.PORT) return <Chip size="small" label="Port" color="secondary" />;
  if (status === CONTAINER_STATUS.SHIPPED) return <Chip size="small" label="Shipped" color="primary" />;
  return <Chip size="small" label="Booked" color="default" />;
};

export const renderCustomerStatus = (status) => {
  if (status === CUSTOMER_STATUSES.ACTIVE) return <Chip variant="outlined" size="small" label="Active" color="success" />;
  return <Chip variant="outlined" size="small" label="Inacvive" color="error" />;
};

export const renderPaymentStatus = (status) => {
  if (status === INVOICE_PAYMENTS_STATUS.COMPLETED) return <Chip variant="outlined" size="small" label="Complete" color="success" />;
  if (status === INVOICE_PAYMENTS_STATUS.CANCELLED) return <Chip variant="outlined" size="small" label="Cancelled" color="error" />;
  return <Chip variant="outlined" size="small" label="Unknown Status" color="default" />;
};

export const renderPaymentType = (value) => {
  if (value === PAYMENT_TYPES.CASH_PAYMENT) return <Chip size="small" label="Cash" color="primary" />;
  if (value === PAYMENT_TYPES.MOBILE_PAYMENT) return <Chip size="small" label="Mobile" color="secondary" />;
  return <Chip size="small" label="Cash" color="primary" />;
};

export const renderConsignmentDispatch = (value) => {
  if (value === DISPATCH_STATUSES.PENDING) return <Chip size="small" label="Pending" color="primary" />;
  if (value === DISPATCH_STATUSES.DISPATCHED) return <Chip size="small" label="Dispatched" color="success" />;
  if (value === DISPATCH_STATUSES.CANCELLED) return <Chip size="small" label="Cancelled" color="error" />;
  return <Chip size="small" label="Unknown Status" color="primary" />;
};

export const renderSMSStatus = (value) => {
  if (value === SMS_STATUS.RECEIVED) return <Chip size="small" label="Received" color="success" />;
  if (value === SMS_STATUS.FAILED) return <Chip size="small" label="Failed" color="error" />;
  if (value === SMS_STATUS.SENT) return <Chip size="small" label="Sent" color="success" />;
  return <Chip size="small" label="Pending" color="primary" />;
};

export const renderActive = (active) =>
  active ? <Chip size="small" label="Active" color="success" /> : <Chip size="small" label="Inactive" color="primary" />;

export function formatPhoneNumber(number) {
  const countryCode = number.slice(0, 3);
  const phoneNumber = number.slice(3);

  if (phoneNumber.length === 9) {
    return {
      countryCode,
      phoneNumber
    };
  }
  return {
    countryCode: '255',
    phoneNumber: '1'.repeat(9)
  };
}

export const roundToThousands = (number) => Math.round(number / 1000) * 1000;

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
