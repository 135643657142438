import { useEffect, useState } from 'react';
import { performAuthenticatedGetRequest } from '../../../utils/api/handlers';
import appNotify from '../../../utils/libs/appNotify';

export default function useMarkets(init = false, initialParams = {}) {
  const [markets, setMarkets] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [marketOptions, setMarketOptions] = useState([]);
  const [marketOptionsLoading, setMarketOptionsLoading] = useState(false);

  const listMarkets = () => {
    setPageLoading(true);
    performAuthenticatedGetRequest(`api/v1/markets/list`, params)
      .then((res) => {
        setMarkets(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to load markets');
        setPageLoading(false);
      });
  };

  const searchMarkets = () => {
    setMarketOptionsLoading(true);
    performAuthenticatedGetRequest(`api/v1/markets/search`, params)
      .then((res) => {
        setMarketOptions(res.results);
        setMarketOptionsLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to search markets');
        setMarketOptionsLoading(false);
      });
  };

  useEffect(() => {
    if (init) {
      listMarkets();
    } else if (params.search && params.search.length > 0) {
      searchMarkets();
    }
  }, [params]);

  return {
    pageLoading,
    setMarkets,
    next,
    markets,
    count,
    listMarkets,
    marketOptionsLoading,
    setParams,
    marketOptions
  };
}
