import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Home as HomeIcon, PinDrop } from '@mui/icons-material';

function ConfigurationsSideBar() {
  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Location', icon: <PinDrop />, path: '/configurations/location', active: true }
  ];

  return (
    <Box
      sx={{
        marginTop: 3,
        width: 240,
        height: 'auto', // Adjust to fit your requirements
        boxSizing: 'border-box',
        bgcolor: 'background.paper'
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            component={NavLink}
            to={item.path}
            key={index}
            style={{ padding: 16, backgroundColor: item.active ? '#f0f0f0' : '' }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ConfigurationsSideBar;
