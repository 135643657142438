import { useEffect, useState } from 'react';
import { performAuthenticatedGetRequest } from '../../../../../utils/api/handlers';
import appNotify from '../../../../../utils/libs/appNotify';

export default function useStates(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [stateOptionsLoading, setStateOptionsLoading] = useState(false);

  const listStates = () => {
    setPageLoading(true);
    performAuthenticatedGetRequest(`api/v1/states/list`, { params })
      .then((res) => {
        setStates(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to load states');
        setPageLoading(false);
      });
  };

  const searchStates = () => {
    setStateOptionsLoading(true);
    performAuthenticatedGetRequest(`api/v1/states/search`, { params })
      .then((res) => {
        setStateOptions(res.results);
        setStateOptionsLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to search states');
        setStateOptionsLoading(false);
      });
  };

  useEffect(() => {
    if (init) {
      listStates();
    } else if (params.search && params.search.length > 0) {
      searchStates();
    }
  }, [params]);

  return {
    pageLoading,
    setStates,
    next,
    states,
    count,
    listStates,
    stateOptionsLoading,
    setParams,
    stateOptions
  };
}
