import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import MarketsSidebar from './MarketsSidebar';

export default function MarketsLayout() {
  return (
    <Grid spacing={16} container sx={{ height: '100vh' }}>
      <Grid item xs={2}>
        <MarketsSidebar />
      </Grid>
      <Grid item xs={10} sx={{ height: '100vh', overflowY: 'auto', mt: 3, pb: 16 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
