import { useEffect, useState } from 'react';
import { performAuthenticatedGetRequest } from '../../../utils/api/handlers';
import appNotify from '../../../utils/libs/appNotify';

export default function useMarketCategories(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOptionsLoading, setCategoryOptionsLoading] = useState(false);

  const listMarketCategories = () => {
    setPageLoading(true);
    performAuthenticatedGetRequest(`api/v1/markets/categories/list`, params)
      .then((res) => {
        setCategories(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to load market categories');
        setPageLoading(false);
      });
  };

  const searchMarketCategories = () => {
    setCategoryOptionsLoading(true);
    performAuthenticatedGetRequest(`api/v1/markets/categories/search`, params)
      .then((res) => {
        setCategoryOptions(res.results);
        setCategoryOptionsLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to search market categories');
        setCategoryOptionsLoading(false);
      });
  };

  useEffect(() => {
    if (init) {
      listMarketCategories();
    } else if (params.search && params.search.length > 0) {
      searchMarketCategories();
    }
  }, [params]);

  return {
    pageLoading,
    setCategories,
    next,
    categories,
    count,
    listMarketCategories,
    categoryOptionsLoading,
    setParams,
    categoryOptions
  };
}
