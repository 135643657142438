import { useEffect, useState } from 'react';
import { performAuthenticatedGetRequest } from '../../../../../utils/api/handlers';
import appNotify from '../../../../../utils/libs/appNotify';

export default function UseNations(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [nations, setNations] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryOptionsLoading, setCountryOptionsLoading] = useState(false);

  const listCountries = () => {
    setPageLoading(true);
    performAuthenticatedGetRequest(`api/v1/countries/list`, { params })
      .then((res) => {
        setNations(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to load countries');
        setPageLoading(false);
      });
  };

  const searchCountries = () => {
    setCountryOptionsLoading(true);
    performAuthenticatedGetRequest(`api/v1/countries/search`, { params })
      .then((res) => {
        setCountryOptions(res.results);
        setCountryOptionsLoading(false);
      })
      .catch((err) => {
        appNotify.error('Failed to search countries');
        setCountryOptionsLoading(false);
      });
  };

  useEffect(() => {
    if (init) {
      listCountries();
    } else if (params.search && params.search.length > 0) {
      searchCountries();
    }
  }, [params]);

  return {
    pageLoading,
    setNations,
    next,
    nations,
    count,
    listCountries,
    countryOptionsLoading,
    setParams,
    countryOptions
  };
}
