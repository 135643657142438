import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import usePayments from '../utils/libs/hooks/use-payments';
import PropTypes from 'prop-types';
import useCustomers from '../utils/libs/hooks/use-customers';
import { useTranslation } from 'react-i18next';
import useSuppliers from '../utils/libs/hooks/use-suppliers';
import useWarehouses from '../utils/libs/hooks/use-warehouses';
import useConsignments from '../utils/libs/hooks/use-consignments';
import useUsers from '../utils/libs/hooks/use-users';
import usePriceCategories from '../utils/libs/hooks/use-price-categories';
import useContainers from '../utils/libs/hooks/use-containers';
import useNations from '../pages/configuration/location/countries/hooks/use-nations';
import useCities from '../pages/configuration/location/cities/hooks/use-cities';
import useStates from '../pages/configuration/location/states/hooks/use-states';
import useMarketCategories from '../pages/markets/hooks/use-market-categories';
import useMarkets from '../pages/markets/hooks/use-markets';

export function AsyncSearchPaymentsField({ onChange, loading, value }) {
  const { paymentOptions, setParams, paymentOptionsLoading } = usePayments(false);
  const { t } = useTranslation();

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, paymentOptions.filter((p) => p.value !== value.value)] : paymentOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('dispatches.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {paymentOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchPaymentsField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchCustomersField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { customerOptions, setParams, customerOptionsLoading } = useCustomers(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];

  return (
    <Autocomplete
      multiple={multiple}
      id="customer-async-field"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? customerOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, customerOptions.filter((c) => value.value !== c.value)]
          : customerOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('customers.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {customerOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchCustomersField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchSuppliersField({ value, onChange, loading }) {
  const { t } = useTranslation();
  const { supplierOptions, setParams, suppliersOptionsLoading } = useSuppliers(false);
  return (
    <Autocomplete
      id="suppliers-async-field"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, supplierOptions.filter((v) => value.value !== v.value)] : supplierOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('suppliers.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {suppliersOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchSuppliersField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchWarehousesField({ value, onChange, loading, width = '100%' }) {
  const { t } = useTranslation();
  const { warehouseOptions, setParams, warehouseOptionsLoading } = useWarehouses(false);
  return (
    <Autocomplete
      id="warehouses-async-field"
      sx={{ width }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, warehouseOptions.filter((v) => value.value !== v.value)] : warehouseOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('warehouses.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {warehouseOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchWarehousesField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  width: PropTypes.any
};

export function AsyncSearchConsignmentsField({ value, onChange, loading, initialParams }) {
  const { t } = useTranslation();
  const { consignmentOptions, setParams, consignmentOptionsLoading } = useConsignments(false, initialParams);
  return (
    <Autocomplete
      id="consignments-async-field"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, consignmentOptions.filter((v) => value.value !== v.value)] : consignmentOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('consignments.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {consignmentOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchConsignmentsField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  initialParams: PropTypes.any
};

AsyncSearchWarehousesField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchUsersField({ value, onChange, loading, initialParams }) {
  const { t } = useTranslation();
  const { usersOptions, setParams, usersOptionsLoading } = useUsers(false, initialParams);
  return (
    <Autocomplete
      id="consignments-async-field"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, usersOptions.filter((v) => value.value !== v.value)] : usersOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('users.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {usersOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchUsersField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  initialParams: PropTypes.any
};

export function AsyncSearchPriceCategoriesField({ value, onChange, loading, initialParams }) {
  const { t } = useTranslation();
  const { priceCategoriesOptions, setParams, priceCategoriesOptionsLoading } = usePriceCategories(false, initialParams);
  return (
    <Autocomplete
      id="price_categories-async-field"
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={value ? [value, priceCategoriesOptions.filter((v) => value.value !== v.value)] : priceCategoriesOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('price_categories.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {priceCategoriesOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchPriceCategoriesField.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  initialParams: PropTypes.any
};

export function AsyncSearchContainersField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { containerOptions, setParams, containerOptionsLoading } = useContainers(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? containerOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, containerOptions.filter((c) => value.value !== c.value)]
          : containerOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('containers.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {containerOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchContainersField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default function AsyncSearchCountriesField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { countryOptions, setParams, countryOptionsLoading } = useNations(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? countryOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, countryOptions.filter((c) => value.value !== c.value)]
          : countryOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('countries.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {countryOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchCountriesField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchCitiesField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { cityOptions, setParams, cityOptionsLoading } = useCities(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? cityOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, cityOptions.filter((c) => value.value !== c.value)]
          : cityOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('cities.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {cityOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchCitiesField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchStatesField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { stateOptions, setParams, stateOptionsLoading } = useStates(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? stateOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, stateOptions.filter((c) => value.value !== c.value)]
          : stateOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('states.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {stateOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchStatesField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchMarketCategoriesField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { categoryOptions, setParams, categoryOptionsLoading } = useMarketCategories(false);
  const valueIDS = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? categoryOptions.filter((o) => !valueIDS.includes(o.value))
          : value
          ? [value, ...categoryOptions.filter((c) => value.value !== c.value)]
          : categoryOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('market_categories.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {categoryOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchMarketCategoriesField.propTypes = {
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export function AsyncSearchMarketField({ value, onChange, loading, multiple }) {
  const { t } = useTranslation();
  const { marketOptions, setParams, marketOptionsLoading } = useMarkets(false);
  const valueIDs = multiple ? value.map((v) => v.value) : [];
  return (
    <Autocomplete
      multiple={multiple}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setParams((prevState) => ({ ...prevState, search: newInputValue }));
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      options={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? marketOptions.filter((o) => !valueIDs.includes(o.value))
          : value
          ? [value, ...marketOptions.filter((m) => value.value !== m.value)]
          : marketOptions
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('markets.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {marketOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AsyncSearchMarketField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  multiple: PropTypes.bool
};
