import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import ConfigurationsSideBar from './ConfigurationsSideBar';

export default function ConfigurationsLayout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <ConfigurationsSideBar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
